import React from 'react';

// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Footer from '../components/footer';
import Header from '../components/header';
import Content from '../components/content';
import SEO from '../components/seo';
import Icon from '../components/icon';
import Analytics from '../components/analytics';


import industryStyle from '../styles/industry.module.less';

export default () => {
    const industryList = [
        {
            industryName: '手机数码',
            backgroundColor: '#b5ecff',
            icon: 'iconphone',
            tag: 'phone'
        },
        {
            industryName: '汽车汽配',
            backgroundColor: '#c0e2ff',
            icon: 'iconcar',
            tag: 'car'
        },
        {
            industryName: '服装服饰',
            backgroundColor: '#d3f6f0',
            icon: 'iconclothing',
            tag: 'clothing'
        },
        {
            industryName: '食品饮料',
            backgroundColor: '#ffe3b9',
            icon: 'iconfood',
            tag: 'food'
        },
        {
            industryName: '珠宝配饰',
            backgroundColor: '#ffcbce',
            icon: 'iconjewerly',
            tag: 'jewerly'
        },
        {
            industryName: '仪器仪表',
            backgroundColor: '#fff9c2',
            icon: 'iconinstrument',
            tag: 'instrument'
        },
        {
            industryName: '日用百货',
            backgroundColor: '#cbe7ff',
            icon: 'icongoods',
            tag: 'goods'
        },
        {
            industryName: '照明开关',
            backgroundColor: '#fff9c2',
            icon: 'iconlight',
            tag: 'light'
        },
        {
            industryName: '纺织皮革',
            backgroundColor: '#d3f6f0',
            icon: 'iconweave',
            tag: 'weave'
        },
        {
            industryName: '印刷包装',
            backgroundColor: '#ffe8c5',
            icon: 'iconpack',
            tag: 'pack'
        },
        {
            industryName: '母婴玩具',
            backgroundColor: '#ffcbce',
            icon: 'icontoy',
            tag: 'toy'
        },
        {
            industryName: '五金机械',
            backgroundColor: '#b5ecff',
            icon: 'iconmetals',
            tag: 'metals'
        },
        {
            industryName: '电脑办公',
            backgroundColor: '#d3f6f0',
            icon: 'iconcompute',
            tag: 'compute'
        },
        {
            industryName: '橡胶化工',
            backgroundColor: '#fff9ba',
            icon: 'iconchemical',
            tag: 'chemical'
        },
        {
            industryName: '米面粮油',
            backgroundColor: '#ffe8c5',
            icon: 'iconrice',
            tag: 'rice'
        },
        {
            industryName: '美容化妆',
            backgroundColor: '#ffcbce',
            icon: 'iconbeauty',
            tag: 'beauty'
        },
        {
            industryName: '模具钢材',
            backgroundColor: '#c0e2ff',
            icon: 'iconpattern',
            tag: 'pattern'
        },
        {
            industryName: '电气电工',
            backgroundColor: '#d3f6f0',
            icon: 'iconelectric',
            tag: 'electric'
        },
        {
            industryName: '手工艺品',
            backgroundColor: '#b5ecff',
            icon: 'iconhandicraft',
            tag: 'handicraft'
        },
        {
            industryName: '行业设备',
            backgroundColor: '#ffe3b9',
            icon: 'iconequipment',
            tag: 'equipment'
        },
        {
            industryName: '户外运动',
            backgroundColor: '#fff9ba',
            icon: 'iconsport',
            tag: 'sport'
        }
    ];

    return (
        <section>
            <SEO title="行业" />
            <Header />
            <Content className={industryStyle.industryList}>
                <div className={industryStyle.industryClassify}>
                    <h3>常见特色行业解决方案示例，点击查看</h3>
                    <h4>高标准服务铸就高水准企业</h4>
                    <div className={industryStyle.industryGroup}>
                        <Content>
                            <ul>
                                {industryList.map((value, index) => (
                                    <li key={index}>
                                        <Link to={`/industry-detail#${value.tag}`} style={{ backgroundColor: value.backgroundColor }}>
                                            <Icon type={value.icon} className={industryStyle.industryGroup__icon}/>
                                            <p>{value.industryName}</p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Content>
                    </div>
                </div>
            </Content>
            <Analytics />
            <Footer />
        </section>
    );
};
